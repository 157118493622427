<template>
  <div class="login">
    <div class="form">
      <!-- 登录表单 -->
      <el-form
        :rules="loginRules"
        :model="loginInfo"
        ref="loginFormRef"
        style="width: 100%"
      >
        <h1>登录</h1>
        <!-- 用户名输入框 -->
        <el-form-item prop="username">
          <el-input v-model="loginInfo.username" placeholder="用户名" />
        </el-form-item>
        <!-- 密码输入框 -->
        <el-form-item prop="password">
          <el-input
            v-model="loginInfo.password"
            type="password"
            placeholder="密码"
            show-password
          />
        </el-form-item>
      </el-form>
      <!-- 用户类型选择 -->
      <el-radio-group v-model="loginInfo.usertype" style="align-self: flex-start">
        <el-radio-button :label="1">学生</el-radio-button>
        <el-radio-button :label="2">教师</el-radio-button>
        <el-radio-button :label="0">管理员</el-radio-button>
      </el-radio-group>

      <!-- 忘记密码和注册按钮 -->
      <div style="align-self: flex-end; margin: 0; margin-bottom: 10px">
        <router-link to="/forget">
          <el-button text type="primary">忘记密码</el-button>
        </router-link>
        <router-link to="/register">
          <el-button text type="primary">注册</el-button>
        </router-link>
      </div>

      <!-- 提交按钮 -->
      <el-button plain type="primary" @click="submit(loginFormRef)">提交</el-button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import { ElLoading, ElMessage } from "element-plus";
import { api } from "@/utils/requests";
import { useRouter } from "vue-router";

// 响应式数据
let loginInfo = reactive({
  username: "",
  password: "",
  usertype: 1,
});
let loginFormRef = ref();
const router = useRouter();

// 登录表单验证规则
const loginRules = reactive({
  username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
  password: [{ required: true, message: "请输入密码", trigger: "blur" }],
});

// 提交表单逻辑
function submit(formEl) {
  if (!formEl) return;
  let loginType = ['/admin', '/student', '/teacher'];
  formEl.validate((valid) => {
    if (valid) {
      const loading = ElLoading.service({
        text: "登录中。。。",
        background: "rgba(255, 255, 255, 0.6)",
        target: ".login .form",
      });
      api.post("/user/login", loginInfo).then((res) => {
        if (res.data.code === 200) {
          // 跳转
          router.replace({ path: loginType[loginInfo.usertype] });
          ElMessage.success("登录成功");
        } else {
          console.log(res);
          ElMessage.error(res.data.code + ":" + res.data.message);
        }
      }).finally(() => {
        loading.close();
      });
    }
  });
}

// 组件挂载时设置页面标题
onMounted(() => {
  document.title = "python兴趣学习平台 - 登录";
});
</script>

<style>
.login {
  width: 100%;
  height: 100%;
  background: url(https://api.kdcc.cn) no-repeat;
  overflow: hidden;
  display: flex;
}
.login .form {
  width: 300px;
  height: 300px;
  margin: auto;
  background-color: rgba(240, 248, 255, 0.404);
  text-align: center;
  align-items: center;
  border-radius: 10px;
  transition: 0.5s;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加浅色阴影效果 */
}
.login .form:hover {
  backdrop-filter: blur(6px);
}
@media screen and (max-width: 400px) {
  .login .form {
    width: 100%;
  }
}
.login h1 {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 400;
}

.login .el-input div {
  background-color: rgba(255, 255, 255, 0.584);
  padding: 5px 10px;
}
</style>
